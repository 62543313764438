import axios from "axios";
import { StripeProduct } from "src/interfaces/StripeProduct";
import { StripePrice } from "src/interfaces/StripePrice";
import { StripePlan } from "src/interfaces/StripePlan";
export default class StripeUtils {

  domain: string;

  stripeURL: string;

  Authorization: string;

  ContentType: string;

  Plans: Array<StripePlan>;

  constructor(domain: string, plans: Array<StripePlan>) {
    this.domain = domain;
    this.stripeURL = "https://api.stripe.com/v1";
    this.Authorization = `Bearer ${process.env.REACT_APP_STRIPE_SK}`;
    this.ContentType = "application/x-www-form-urlencoded";
    this.Plans = plans;
  }

  createCheckoutSession = async (id: string, email: string, returnRoute: string, metadata: Record<string, any>) => {
    try {

      const customer = await this.searchCustomerByEmail(email);

      const data = {
        mode: "subscription",
        customer: typeof customer[0] !== "undefined" ? customer[0].id : undefined,
        success_url: `${this.domain}/${returnRoute}?success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${this.domain}/${returnRoute}?canceled=true`,
        customer_email: email,
        allow_promotion_codes: true,
        consent_collection: {terms_of_service: 'required'},
        custom_text: {terms_of_service_acceptance: 'Agree to Backpac\'s Terms of Service'},
        line_items: [
          {
            // price: prices.data[0].id,
            price: id,
            // For metered billing, do not pass quantity
            quantity: 1,
          },
        ],
        payment_method_types: [
          "card"
        ],
        metadata,
        // custom_fields: [
        //   {key: metadata['account-id'], label: 'accountId', type: 'text'}
        // ]
      };
      const qs = require("qs");
      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/checkout/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: qs.stringify(data),
      });
      return response.data.url;
    } catch (error: any) {
      console.error(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };

  cartCheckoutSession = async (line_items: Array<any>, email: string, returnRoute: string, metadata: Record<string, any>) => {
    try {
      const customer = await this.searchCustomerByEmail(email);
      const data = {
        mode: 'subscription',
        success_url: `${this.domain}/${returnRoute}?success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${this.domain}/${returnRoute}?canceled=true`,
        automatic_tax: { enabled: true },
        allow_promotion_codes: true,
        consent_collection: { terms_of_service: 'required' },
        custom_text: {
          terms_of_service_acceptance: {
            message: 'You agree to adhere to Backpac\'s Terms of Service'
          },
          submit:{
            message: '*** Please Note: Backpac does not provide RPC nodes. You\'ll you need to configure your own or Provider RPC node endpoints.'
          }
        },
        line_items,
        payment_method_types: ['card'],
        metadata
        // custom_fields: [
        //   {key: metadata['account-id'], label: 'accountId', type: 'text'}
        // ]
      };

      if(typeof customer[0] !== "undefined") {
        data['customer'] = customer[0].id;
      }else{
        data['customer_email'] = email;
      };

      //console.log(line_items)

     // return null

      const qs = require("qs");
      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/checkout/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: qs.stringify(data),
      });
      return response.data.url;
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  retrieveProduct = async (productId: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products/${productId}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };

  sortProductsByIndex = (arr: Array<StripeProduct>): Array<StripeProduct> => {
    return arr.sort((a, b) => Number(a.metadata.index) - Number(b.metadata.index));
  };

  retrieveProducts = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label.endsWith('-ui')){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };

  retrieveActiveProducts = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(typeof product.metadata.ui !== "undefined" && product.metadata.ui === 'true'){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  retrieveBackpacProducts = async () => {
    try {
      //Make the call for the products
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      //Hold a list of products to show to the UI
      const products: Array<StripeProduct> = [];
      //Grab the list of products
      const _products = response.data.data;

      //Loop the products and get the prices
      for(const product of _products){
        //Make sure this product should be shown in the UI
        if(product.unit_label !== null && product.unit_label.endsWith('-ui')){
          const price = await this.retrievePrice(product.default_price);
         //Make sure we have a price for the product, otherwise don't add it for display
          if(typeof price !== "undefined"){
            product.price = price;
            products.push(product as StripeProduct);
          }
        }
      }

      return this.sortProductsByIndex(products);
    } catch (error: any) {
      console.log(error);
      console.log(error?.data.error.message);
      throw error;
    }
  };

  retrievePrice = async (priceId: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/prices/${priceId}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };

  retrieveSession = async (id: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/checkout/sessions/${id}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };

  retrieveSessionLineItems = async (id: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/checkout/sessions/${id}/line_items`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };

  createBillingPortalSessionByEmail = async (email: string) => {
    try {
      const customer = await this.searchCustomerByEmail(email);

      //Make sure the customer exists within stripe
      if (customer.length === 0) {
        //If they don't exists stop here
        return { success: false };
      }

      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/billing_portal/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: {
          customer: customer[0].id,
          return_url: `${this.domain}/account`,
        },
      });
      return {url: response.data.url, customer: response.data.customer};
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };

  createBillingPortalSession = async (customer: string) => {
    try {
  

      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/billing_portal/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: {
          customer,
          return_url: `${this.domain}/management/account/details`,
        },
      });
      return {url: response.data.url, customer: response.data.customer};
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  searchCustomerByEmail = async (email: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/customers?email=${email}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error);
      throw error;
    }
  };

  listSubscriptions = async (id: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/subscriptions?customer=${id}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
}