import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from './useUser';
import { AxiosError } from 'axios';
import { User } from 'src/models/user';
import {AccountAPI} from 'src/class/model/Account';
import Endpoint from 'src/class/Endpoint';
import { useLocalStorage } from './useLocalStorage';
import { CryptoUtils } from 'src/class/CryptoUtils';
import {Authorizer} from 'casbin.js';
import CasbinService from 'src/class/CasbinService';
interface UseAuthResult {
  loginUser: (data: { jwt: string; provider: string }) => Promise<boolean>;
  logoutUser: () => Promise<boolean>;
  hasPermission: (resource: string, action: string) => Promise<boolean>;
  can: (resource: string, action: string) => Promise<boolean>;
  cannot: (resource: string, action: string) => Promise<boolean>;
  updateSubscription: (session_id: string) => Promise<boolean>;
  isLoading: boolean;
  user: User | null;
  error: AxiosError | null; // Use AxiosError type for error
}

export default function useAuth(): UseAuthResult {
  const navigate = useNavigate();
  const { user, addUser, removeUser } = useUser();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<AxiosError | null>(null);
  const endpoint = new Endpoint(); // Use AxiosError type for error
  const { setWithExpiry, getWithExpiry } = useLocalStorage();
  const domain = window.location.origin;
  const [ auth ] = useState(new Authorizer("manual"));
  
  //let casbinService = new CasbinService(user.policyJSON);

  useEffect(() => {
    const user = getWithExpiry('backpac-rpc-lb-user');
    if (user) {
      const parsedUser = JSON.parse(user);
      //console.log('expiresAt', parsedUser.expiresAt);
      addUser(JSON.parse(user));
    }
    loadPolicy();
    setLoading(false);
  }, []);

  // Set user in context and navigate to home
  // Login user
  const loginUser = async (data: {
    jwt: string;
    provider: string;
  }): Promise<boolean> => {
    const { jwt, provider } = data;
    try {
      const userResponse =
        provider !== 'backpac'
          ? await endpoint.getApiClient(`/qn/sso/${jwt}`)
          : await endpoint.postApiClient(`/dashboard/sso/`, { jwt });

      if (
        typeof userResponse.data !== 'undefined' &&
        userResponse.data.valid === false
      ) {
        const hashed = CryptoUtils.hash(
          JSON.stringify({ title: 'Login', message: userResponse.data.message })
        );

        if (provider === 'backpac') {
          window.location.href =
            process.env.REACT_APP_BASE +
            `/?m=${hashed}&msg=${userResponse.data.message}&t=Login`;
        } else {
          navigate(`/?m=${hashed}&msg=${userResponse.data.message}&t=Login`);
        }
      } else if (
        typeof userResponse.data !== 'undefined' &&
        userResponse.data.valid === true
      ) {
        let user: User = userResponse.data.user;
        user.jwt = jwt;

        //dispatch(setJwtToken(jwt));
        //localStorage.setItem('jwt', jwt);

        //return await setUserContext(user);
        addUser(user);
        setLoading(false);
        navigate('/management/apps');
      } else {
        const hashed = CryptoUtils.hash(
          JSON.stringify({ title: 'Login', message: userResponse.data.message })
        );

        if (provider === 'backpac') {
          window.location.href =
            process.env.REACT_APP_BASE +
            `/?m=${hashed}&msg=${userResponse.data.message}&t=Login`;
        } else {
          navigate(`/?m=${hashed}&msg=${userResponse.data.message}&t=Login`);
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      setError(err); // Set the error directly
      return false;
    }
  };

 

  const logoutUser = async (): Promise<boolean> => {
    try {
      removeUser();
      //localStorage.removeItem('jwt');
      navigate('/');
      return true;
    } catch (err) {
      setError(err); // Set the error directly
      return false;
    }
  };

 const accountAPI = new AccountAPI(user, logoutUser, domain);

  const hasPermission = async (resource: string, action: string): Promise<boolean> => {
    try {
      //Load the policy
      await loadPolicy();

      //Respond
      return await auth.can(action, resource);
    } catch (err) {
      setError(err);
      return false;
    }
  };

  const can = async (resource: string, action: string): Promise<boolean> => {
    try {
      await loadPolicy();

      return await auth.can(action, resource);
    } catch (err) {
      setError(err);
      return false;
    }
  };

  const cannot = async (resource: string, action: string): Promise<boolean> => {
    try {
      await loadPolicy();

      return await auth.cannot(action, resource);
    } catch (err) {
      setError(err);
      return false;
    }
  };

  const updateSubscription = async (session_id: string): Promise<boolean> => {
    try {
      console.log({user, session_id})
      return true;
    } catch (err) {
      setError(err); // Set the error directly
      return false;
    }
  };

  const loadPolicy = async (): Promise<boolean> => {
    try {
      //Check if the policy is cached
      let policy = getWithExpiry('backpac-rpc-lb-account');
      //If not, grab it from the server
      if (policy === null) {
        //Grab the policy
        const policyResp = await accountAPI.policy();
        //Check if the resp is ok
        policy = policyResp.policy;
        setWithExpiry("backpac-rpc-lb-account", policy, 6000);
      };

      //Set the policy
      auth.setPermission(policy);
      return true;
    } catch (error) {
      console.error('Error loading account policy:', error);
      return false;
    }
  };

  return {
    loginUser,
    logoutUser,
    hasPermission,
    can,
    cannot,
    updateSubscription,
    isLoading,
    user,
    error
  };
}
