import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import PrivateRoute from './PrivateRoute'
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));
const SSO = Loader(lazy(() => import('src/content/sso')));

// Dashboards
const Listeners = Loader(lazy(() => import('src/content/dashboards/Listeners')));
const ListenersOrder = Loader(lazy(() => import('src/content/dashboards/ListenerOrder')));
const Applications = Loader(lazy(() => import('src/content/dashboards/Applications')));
const TargetGroups = Loader(lazy(() => import('src/content/dashboards/TargetGroups')));
// const Subscriptions = Loader(
//   lazy(() => import('src/content/applications/Subscriptions'))
// );

// Applications

const AppAdd = Loader(
  lazy(() => import('src/content/applications/Apps/settings'))
);

const ListenerRuleAdd = Loader(
  lazy(() => import('src/content/applications/Listeners/settings'))
);


const Providers = Loader(
  lazy(() => import('src/content/applications/Providers'))
);
const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);



const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Overview />,
        
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />,
        
      },
      {
        path: '/sso',
        element: <SSO />,
        
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />,
        
      }
    ]
  },
  {
    path: 'lb',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="listeners" replace />,
        
      },
      {
        path: 'listeners',
        element: <PrivateRoute component={Listeners}/>,
        
      },
      {
        path: 'target-groups',
        element: <PrivateRoute component={TargetGroups}/>,
        
      },
      {
        path: 'app',
        children: [
          {
            path: '',
            element: <Navigate to="add" replace />,
            
          },
          {
            path: 'edit',
            element: <UserProfile />,
            
          },
          {
            path: 'add',
            element: <PrivateRoute component={AppAdd}/>,
            
          }
        ]
      },
      {
        path: 'listener',
        children: [
          {
            path: '',
            element: <Navigate to="add" replace />,
            
          },
          {
            path: 're-order/:chain/:network/:app',
            element: <PrivateRoute component={ListenersOrder}/>,
            
          },
          {
            path: 'add',
            element: <PrivateRoute component={ListenerRuleAdd}/>,
            
          }
        ]
      }
    ]
  },
  {
    path: 'management',
    element: <SidebarLayout />,
    children: [
      {
        path: '',
        element: <Navigate to="apps" replace />,
        
      },
      {
        path: 'apps',
        element: <PrivateRoute component={Applications}/>,
        
      },
      {
        path: 'providers',
        element: <PrivateRoute component={Providers}/>,
        
      },
      {
        path: 'account',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <PrivateRoute component={UserSettings}/>
          },
          // {
          //   path: 'settings',
          //   element: <UserSettings />
          // }
        ]
      }
    ]
  },
];

export default routes;
